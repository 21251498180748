import { Link, Typography } from "@mui/material";
import { appVersion } from "utils/getAppVersion";

export default function Copyright(props) {
  return (
    <Typography variant="body2" color={props.color} align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" target="_blank" href="http://abcerequest.com.ph/">
        eRequest Client
      </Link>{" "}
      {new Date().getFullYear()} {"v " + appVersion}
    </Typography>
  );
}
