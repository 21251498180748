import {
  Modal,
  Form,
  Input,
  Button,
  Popconfirm,
  DatePicker,
  Switch,
} from "antd";
import { useDispatch } from "react-redux";
import {
  createHealthcareProvider,
  deleteHealthcareProvider,
  updateHealthcareProvider,
} from "store";
import moment from "moment";
import useCheckPermission from "hooks/useCheckPermission";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import { useCallback, useEffect, useState } from "react";

const CreateUpdateHealthcareProvider = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    handleSubmit,
    isForUpdate,
    idForUpdate,
  } = props;
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToDelete = handleVerifyPermission(
    "Laboratory:HealthcareProvider:Delete"
  );

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const LoadUpdateData = useCallback(() => {
    form.setFieldsValue({
      firstName: idForUpdate.firstName,
      lastName: idForUpdate.lastName,
      licenseNumber: idForUpdate.licenseNumber,
      licenseExpirationDate:
        idForUpdate.licenseExpirationDate === null
          ? ""
          : moment(idForUpdate.licenseExpirationDate),
      title: idForUpdate.title,
      specialty: idForUpdate.specialty,
      clientId: idForUpdate.clientId,
      phoneNumber: idForUpdate.phoneNumber,
      email: idForUpdate.email,
      isActive: idForUpdate.isActive,
    });
  }, [form, idForUpdate]);

  useEffect(() => {
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [isForUpdate, LoadUpdateData]);

  const onFinish = (values) => {
    if (isForUpdate) {
      if (
        updateHealthcareProvider.pending().type ===
        "healthcareProviders/update-healthcare-provider/pending"
      ) {
        setLoading(true);
      }
      const data = {
        ...values,
        id: idForUpdate.id,
      };
      dispatch(updateHealthcareProvider(data)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Updated Healthcare Provider",
            description: `Successfully updated ${
              values.firstName + " " + values.lastName
            }`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Update Healthcare Provider",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    } else {
      if (
        createHealthcareProvider.pending().type ===
        "healthcareProviders/create-healthcare-provider/pending"
      ) {
        setLoading(true);
      }
      dispatch(createHealthcareProvider(values)).then((val) => {
        if (val.payload?.success) {
          handleSubmit();
          setLoading(false);
          notification.success({
            message: "Created Healthcare Provider",
            description: `Successfully created ${
              values.firstName + " " + values.lastName
            }`,
          });
        }
        if (!val.payload?.success) {
          notification.error({
            message: "Failed to Create Healthcare Provider",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
          setLoading(false);
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    // eslint-disable-next-line
    errorInfo.errorFields.map((item) => {
      notification.error({
        message: "Required Field",
        description: item?.errors[0].props.children,
      });
    });
  };

  const handleClickDelete = () => {
    //HANDLE LOADING
    if (
      deleteHealthcareProvider.pending().type ===
      "healthcareProviders/delete-healthcare-provider/pending"
    ) {
      setLoading(true);
    }
    dispatch(deleteHealthcareProvider(idForUpdate.id)).then((val) => {
      if (val.payload?.success) {
        handleSubmit();
        setLoading(false);
        notification.success({
          message: "Deleted Healthcare Provider",
          description: `Successfully deleted ${
            idForUpdate.firstName + " " + idForUpdate.lastName
          }`,
        });
      }
      if (!val.payload?.success) {
        notification.error({
          message: "Failed to Delete Healthcare Provider",
          description: errorMessagesDescription(
            val.payload?.response.data.errorMessages
          ),
        });
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={
        isForUpdate
          ? "Update Healthcare Provider"
          : "Create Healthcare Provider"
      }
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        name="healthcareProvider"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        form={form}
      >
        {/* First Name */}
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: <i>{"Please input First Name."}</i>,
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>

        {/* Last Name */}
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: <i>{"Please input Last Name."}</i>,
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>
        {/* License Number */}
        <Form.Item label="License Number" name="licenseNumber">
          <Input placeholder="License Number" />
        </Form.Item>
        {/* License Expiration Date */}
        <Form.Item label="License Expiration Date" name="licenseExpirationDate">
          <DatePicker />
        </Form.Item>
        {/* Title */}
        <Form.Item label="Title" name="title">
          <Input placeholder="Title" />
        </Form.Item>

        {/* Specialty */}
        <Form.Item label="Specialty" name="specialty">
          <Input placeholder="Specialty" />
        </Form.Item>

        {/* Phone Number */}
        <Form.Item label="Phone Number" name="phoneNumber">
          <Input placeholder="Phone Number" />
        </Form.Item>

        {/* Email */}
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>

        {/* Status */}
        {isForUpdate && (
          <Form.Item label="Status" name="isActive" valuePropName="checked">
            <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
          </Form.Item>
        )}

        {/* Submit Button */}
        <Form.Item>
          <Button
            loading={loading}
            type="primary"
            htmlType="submit"
            style={{ margin: 2 }}
            className="submitBtn"
          >
            Submit
          </Button>
          {accessRightsToDelete && isForUpdate ? (
            <Popconfirm
              title="Delete the healthcare provider"
              description="Are you sure to delete?"
              onConfirm={handleClickDelete}
              okText="Yes"
              cancelText="No"
              okButtonProps={{ loading: loading, className: "submitBtn" }}
            >
              <Button loading={loading} type="primary" danger>
                Delete
              </Button>
            </Popconfirm>
          ) : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateHealthcareProvider;
