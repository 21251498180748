import { Grid, Button } from "@mui/material";
import { clientLookUp, getAllHealthcareProvider } from "store/index";
import { useDispatch } from "react-redux";
import {
  Table,
  Typography as TypographyAntD,
  Input,
  ConfigProvider,
  Select,
  Button as BtnAntD,
  Modal as ModalAntD,
  Space,
  Tag,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InfoIcon from "@mui/icons-material/Info";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import useDebounce from "hooks/useDebounce";
import CreateUpdateHealthcareProvider from "./createUpdateHealthcareProvider";
import { useCurrentHeight } from "utils/getScreenSize";
import useCheckPermission from "hooks/useCheckPermission";
import { useCallback, useEffect, useState } from "react";
import { EmptyData } from "components/mnl/global/emptyData";

const GetAllHealthcareProvider = () => {
  const { Paragraph, Text } = TypographyAntD;
  const dispatch = useDispatch();
  const { handleVerifyPermission } = useCheckPermission();
  const accessRightsToModify = handleVerifyPermission(
    "Laboratory:HealthcareProvider:Modify"
  );
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedInputValue = useDebounce(searchText);
  const [sortStr, setSortStr] = useState("title");
  const [sortDirection, setSortDirection] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [healthcareProviderData, setHealthcareProviderData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(1);

  const [isForUpdate, setIsForUpdate] = useState(false);
  const [idForUpdate, setIdForUpdate] = useState(0);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getAllHealthcareProvider.pending().type ===
      "healthcareProvider/get-all-healthcare-provider/pending"
    ) {
      setIsLoading(true);
    }
    dispatch(clientLookUp()).then((data) => {
      setClientData(data.payload.data);
    });
    dispatch(
      getAllHealthcareProvider({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: current,
        sortField: sortStr,
        sortDirection: sortDirection,
      })
    ).then((element) => {
      if (
        element.type ===
        "healthcareProvider/get-all-healthcare-provider/fulfilled"
      ) {
        setIsLoading(false);
        setHealthcareProviderData(element.payload.data.items);
        setTotal(element.payload.data.totalPages * pageSize);
      }
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortStr,
    current,
    sortDirection,
  ]);

  useEffect(() => {
    LoadData();
  }, [dispatch, LoadData]);

  //MODAL FOR INFO USER
  const [openInfo, setOpenInfo] = useState(false);
  const [selectedInfoDetails, setSelectedInfoDetails] = useState(null);

  const showModalInfo = () => {
    setOpenInfo(true);
  };
  const handleOkInfo = () => {
    setOpenInfo(false);
  };
  const handleCancelInfo = () => {
    setOpenInfo(false);
  };

  const onChange = (page) => {
    setCurrent(page);
  };
  const onShowSizeChange = (current, pageSize) => {
    setPageSize(pageSize);
  };
  const handleChangeSearch = (val) => {
    setIsLoading(true);
    setSearchText(val.target.value);
    setCurrent(1);
  };

  const renderEmptyData = () => (
    <EmptyData
      description="No Healthcare Provider Detected"
      action={showCreateUpdate}
      btnTitle="Add New Healthcare Provider"
      renderButton={accessRightsToModify}
    />
  );

  //MODAL CREATE UPDATE
  const [openCreateUpdate, setOpenCreateUpdate] = useState(false);

  const handleCancel = () => {
    setOpenCreateUpdate(false);
    setIsForUpdate(false);
    setIdForUpdate(0);
  };
  const showCreateUpdate = () => {
    setOpenCreateUpdate(true);
  };
  const handleSubmit = () => {
    LoadData();
    setOpenCreateUpdate(false);
  };

  const handleUpdate = (id) => {
    setIsForUpdate(true);
    setIdForUpdate(id);
  };

  const handleChangeSortField = (value) => {
    setSortStr(value);
  };

  const handleChangeSortDirection = (value) => {
    setSortDirection(value);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: 100,
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      width: 150,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      width: 150,
    },
    {
      title: "Specialty",
      dataIndex: "specialty",
      key: "specialty",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "isActive",
      key: "isActive",
      width: 60,
      render: (value) => (
        <Tag color={value ? "success" : "error"}>
          {value ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      width: 150,
      render: (rec, row) => (
        <>
          <BtnAntD
            size="small"
            type="primary"
            icon={<InfoIcon fontSize="inherit" />}
            onClick={() => {
              showModalInfo();
              setSelectedInfoDetails(row);
            }}
            className="bg-blue-600 text-white hover:enabled:bg-blue-400 hover:enabled:text-white"
          >
            Details
          </BtnAntD>
          {accessRightsToModify ? (
            <BtnAntD
              size="small"
              type="text"
              style={{
                marginLeft: 2,
              }}
              icon={<EditIcon fontSize="inherit" />}
              className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
              onClick={() => {
                handleUpdate(row);
                showCreateUpdate();
              }}
            >
              Update
            </BtnAntD>
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            size=""
            placeholder="Search Healthcare Provider"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            defaultValue={sortStr}
            onChange={handleChangeSortField}
            style={{
              width: 120,
            }}
            options={[
              {
                value: "title",
                label: "Title",
              },
              {
                value: "firstName",
                label: "First Name",
              },
              {
                value: "lastName",
                label: "Last Name",
              },
              {
                value: "specialty",
                label: "Specialty",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              width: 120,
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>

        {healthcareProviderData.length !== 0 && accessRightsToModify ? (
          <Grid item xs={12} md={4} lg={4}>
            <Button
              startIcon={<AddOutlinedIcon />}
              variant="text"
              onClick={showCreateUpdate}
            >
              New Healthcare Provider
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={
            accessRightsToModify
              ? columns
              : columns.filter((col) => col.dataIndex !== "")
          }
          dataSource={healthcareProviderData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            current: current,
            onChange: onChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>

      {openInfo ? (
        <ModalAntD
          title={
            <Paragraph>
              <blockquote>
                {selectedInfoDetails.title +
                  " " +
                  selectedInfoDetails.firstName +
                  " " +
                  selectedInfoDetails.lastName +
                  " - " +
                  selectedInfoDetails.specialty}
              </blockquote>
            </Paragraph>
          }
          open={openInfo}
          onOk={handleOkInfo}
          onCancel={handleCancelInfo}
          footer={null}
        >
          <Space direction="vertical">
            <Space align="center">
              Client
              <Text code>
                {
                  clientData.filter(
                    (element) => element.id === selectedInfoDetails.clientId
                  )[0].clientName
                }
              </Text>
            </Space>
            <Space align="center">
              Phone Number
              <Text code>
                {selectedInfoDetails.phoneNumber === "" ||
                selectedInfoDetails.phoneNumber === null
                  ? "N/A"
                  : selectedInfoDetails.phoneNumber}
              </Text>
            </Space>
            <Space align="center">
              Email
              <Text code>
                {selectedInfoDetails.email === "" ||
                selectedInfoDetails.email === null
                  ? "N/A"
                  : selectedInfoDetails.email}
              </Text>
            </Space>
          </Space>
        </ModalAntD>
      ) : null}
      <CreateUpdateHealthcareProvider
        handleCancel={handleCancel}
        openCreateUpdate={openCreateUpdate}
        handleSubmit={handleSubmit}
        isForUpdate={isForUpdate}
        idForUpdate={idForUpdate}
        clientData={clientData}
      />
    </>
  );
};
export default GetAllHealthcareProvider;
