import {
  Modal,
  Select,
  Switch,
  Form,
  Input,
  Radio,
  Button,
  InputNumber,
} from "antd";
import {
  getLaboratoryTestType,
  getLabDeptLookUp,
  createLabTest,
  getLaboratoryTest,
  updateLabTest,
  getLabTestRequirements,
  getAllSpecimen,
  clientLookUp,
  hasSecondAuthorityDeleteLabtest,
} from "store";
import { useDispatch } from "react-redux";
import "./createUpdateLabTest.css";
import TestSelection from "./testSelection";
import { Box } from "@mui/material";
import DeleteLabtestForm from "./deleteLabtestForm";
import { weekOptions } from "./data";
import AddSchedule from "./addSchedule";
import AddLabtestRequirements from "./addLabtestRequirements";
import useCheckPermission from "hooks/useCheckPermission";
import notification from "components/mnl/global/openNotification";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import PopConfirm from "components/mnl/global/popConfirm";
import { sortDataAlphabetically } from "utils/sortDataAlphabetically";
import { useCallback, useEffect, useState } from "react";

const CreateUpdateLabTest = (props) => {
  const {
    handleCancel,
    openCreateUpdate,
    refetchLabtestData,
    isForUpdate,
    idForUpdate,
    setIdForUpdate,
    setSearchText,
  } = props;

  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [testTypes, setTestTypes] = useState([]);
  const [selectedtestType, setSelectedTestType] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const [labDeptList, setLabDeptList] = useState([]);
  const [selectedReqId, setSelectedReqId] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [savedSchedule, setSavedSchedule] = useState([]);
  const [labTestReqArr, setLabTestReqArr] = useState([]);
  const [selectedTest, setSelectedTest] = useState([]);
  const [labTestReqInputs, setLabTestReqInputs] = useState({
    idForReference: 0,
    requirementType: 0,
    isRequired: true,
    requirementDetails: "",
    groupIdentifier: null,
    isGroup: false,
    requirementTemplateId: null,
  });
  const enableModalScroll = selectedtestType !== "";
  const [scheduleInputs, setScheduleInputs] = useState({
    id: null,
    testDetailsId: null,
    day: null,
    timePeriod: 0,
  });
  const [specimen, setSpecimen] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [showDeleteForm, setShowDeleteForm] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  const days = savedSchedule?.map((schedule) => schedule.day);
  const { handleVerifyPermission } = useCheckPermission();

  //Instead of local storage, to avoid user from editing permission key
  const hasSecondAuthorityClaim = handleVerifyPermission(
    "Administration:Authority:SecondAuthority"
  );

  const isAuthorizedToDelete = handleVerifyPermission(
    "Laboratory:LabTest:Delete"
  );

  const filteredWeekOption = weekOptions?.filter(
    (option) => !days?.includes(option?.value)
  );

  const handleChangeReqDetails = (value) => {
    setLabTestReqInputs((prev) => ({
      ...prev,
      requirementDetails: value,
    }));
  };

  const handleChangeRequirementTemplateId = (value) => {
    setLabTestReqInputs((prev) => ({
      ...prev,
      requirementTemplateId: value,
    }));
  };

  const handleChangeGroupIdentifier = (value) => {
    setLabTestReqInputs((prev) => ({
      ...prev,
      groupIdentifier: value,
    }));
  };

  const handleChangeIsGroup = (value) => {
    setLabTestReqInputs((prev) => ({
      ...prev,
      isGroup: value,
    }));
  };

  const handleTimePeriodChange = (e) => {
    setScheduleInputs({
      ...scheduleInputs,
      timePeriod: e.target.value,
    });
  };

  const handleShowDeleteForm = () => {
    setShowDeleteForm(true);
  };
  const handleHideDeleteForm = () => {
    setShowDeleteForm(false);
  };
  const handleScheduleChange = (value) => {
    setScheduleInputs({
      ...scheduleInputs,
      day: value,
    });
  };

  const handleAddSchedule = () => {
    if (days.includes(scheduleInputs.day)) return;
    if (isForUpdate) {
      setSavedSchedule([
        ...savedSchedule,
        {
          day: scheduleInputs.day,
          id: 0,
          testDetailsId: 0,
          timePeriod: scheduleInputs.timePeriod,
        },
      ]);
      setScheduleInputs({
        ...scheduleInputs,
        id: null,
        testDetailsId: null,
        day: null,
      });
    } else {
      setSavedSchedule([
        ...savedSchedule,
        { day: scheduleInputs.day, timePeriod: scheduleInputs.timePeriod },
      ]);
      setScheduleInputs({
        ...scheduleInputs,
        id: null,
        testDetailsId: null,
        day: null,
      });
    }
  };

  const handleRemoveSchedule = (selectedDay) => {
    const filteredSchedule = savedSchedule.filter(
      (schedule) => schedule.day !== selectedDay
    );
    setSavedSchedule(filteredSchedule);
  };

  const handleSelectReqType = (value) => {
    setLabTestReqInputs((prev) => ({
      ...prev,
      requirementType: value,
    }));
  };

  const clearFormInputs = () => {
    form.setFieldsValue({
      name: "",
      code: "",
      type: "",
      clientId: null,
      orderable: true,
      applicableTo: "",
      abbreviation: "",
      departmentId: "",
      methodology: "",
      samplePreparation: "",
      specimen: "",
      specimenContainer: "",
      cutoff: "",
      tatInHours: "",
      sendOut: "",
      isStatAllowed: "",
      schedules: [],
      labTestRequirements: [],
      maxSpecimenAgeInHours: "",
      labTestSpecimenIds: [],
    });
  };

  const handleCloseModal = () => {
    handleCancel();
    setLabTestReqArr([]);
    clearFormInputs();
    setSelectedTestType("");
    setSavedSchedule([]);
    setShowDeleteForm(false);
    setIdForUpdate(null);
    setSelectedData([]);
    setUpdateData(null);
    setSelectedTest([]);
    setSelectedGender("");
    setLabTestReqInputs({
      idForReference: 0,
      requirementType: 0,
      isRequired: true,
      requirementDetails: "",
      groupIdentifier: null,
      isGroup: false,
      requirementTemplateId: null,
    });
  };

  const handleSelectIfRequired = (value) => {
    setLabTestReqInputs({
      ...labTestReqInputs,
      isRequired: value,
    });
  };

  const handleSaveLabTestReq = () => {
    if (selectedReqId !== null) {
      setLabTestReqArr((prevLabTest) =>
        prevLabTest.map((test) =>
          test?.idForReference === selectedReqId
            ? {
                ...test,
                ...{
                  ...labTestReqInputs,
                  groupIdentifier: labTestReqInputs.isGroup
                    ? labTestReqInputs.groupIdentifier
                    : null,
                },
              }
            : test
        )
      );
      setSelectedReqId(null);
      notification.success({
        message: "Updated Requirement Details",
        description: "Successfully updated requirement details",
      });
    } else {
      if (isForUpdate) {
        setLabTestReqArr([
          ...labTestReqArr,
          {
            id: labTestReqArr?.length === 0 ? 0 : labTestReqArr?.length,
            ...{
              ...labTestReqInputs,
              groupIdentifier: labTestReqInputs.isGroup
                ? labTestReqInputs.groupIdentifier
                : null,
            },
          },
        ]);
      } else {
        setLabTestReqArr([
          ...labTestReqArr,
          {
            idForReference:
              labTestReqArr?.length === 0 ? 0 : labTestReqArr?.length,
            ...labTestReqInputs,
            groupIdentifier: labTestReqInputs.isGroup
              ? labTestReqInputs.groupIdentifier
              : null,
          },
        ]);
      }
      notification.success({
        message: "Added Requirement Details",
        description: "Successfully added requirement details",
      });
    }

    setLabTestReqInputs({
      idForReference: 0,
      requirementType: 0,
      isRequired: true,
      requirementDetails: "",
      groupIdentifier: null,
      isGroup: false,
      requirementTemplateId: null,
    });
  };

  const handleSelectReqToEdit = (selectedReq) => {
    setLabTestReqInputs({
      ...selectedReq,
    });
    setSelectedReqId(selectedReq.idForReference);
  };

  const handleDiscardChanges = () => {
    setLabTestReqInputs({
      requirementType: 0,
      isRequired: true,
      requirementDetails: "",
      groupIdentifier: null,
      isGroup: false,
      requirementTemplateId: null,
    });
    setSelectedReqId(null);
  };

  const handleRemoveSavedLabTestReq = (selectedTestInfoId) => {
    const filteredTestReqArr = labTestReqArr.filter(
      (test) => test.idForReference !== selectedTestInfoId
    );
    setLabTestReqArr(filteredTestReqArr);
  };

  useEffect(() => {
    if (idForUpdate !== null) {
      dispatch(
        getLabTestRequirements({
          id: idForUpdate,
        })
      ).then((res) => {
        setLabTestReqArr(
          res.payload.data?.testDetails?.labTestRequirements?.map((item) => ({
            ...item,
            idForReference: item.id,
          }))
        );
      });
    }
  }, [dispatch, idForUpdate]);

  const LoadData = useCallback(() => {
    //HANDLE LOADING
    if (
      getLaboratoryTestType.pending().type ===
      "labtests/get-laboratory-test-types/pending"
    ) {
      setLoading(true);
    }

    dispatch(getLaboratoryTestType()).then((val) => {
      if (val.type === "labtests/get-laboratory-test-types/fulfilled") {
        setTestTypes(val.payload);
        setLoading(false);
        dispatch(
          getAllSpecimen({
            search: "",
            pageSize: 999,
            pageNumber: 1,
            sortField: "Id",
            sortDirection: 0,
          })
        ).then((spc) => {
          const isFetchSuccessful = spc?.payload?.success;

          if (isFetchSuccessful) {
            setSpecimen(spc?.payload?.data?.items);
          }
        });

        dispatch(clientLookUp()).then((item) => {
          const isClientDataFetchedSuccessfully = item?.payload?.success;
          if (isClientDataFetchedSuccessfully) {
            setClientData(item.payload.data);
          }
        });
      }
    });
  }, [dispatch]);

  const LoadLabDeptData = useCallback(() => {
    //HANDLE LOADING
    if (
      getLabDeptLookUp.pending().type === "labDept/get-lab-dept-look-up/pending"
    ) {
      setLoading(true);
    }

    dispatch(getLabDeptLookUp()).then((val) => {
      if (val.type === "labDept/get-lab-dept-look-up/fulfilled") {
        const labDeptListTemp = val.payload.data.map((val) => {
          return { value: val.id, label: val.name };
        });
        setLabDeptList(labDeptListTemp);
        setLoading(false);
      }
    });
  }, [dispatch]);

  const LoadUpdateData = useCallback(() => {
    //HANDLE LOADING
    if (getLaboratoryTest.pending().type === "labTest/get-lab-test/pending") {
      setLoading(true);
    }
    if (idForUpdate !== null) {
      dispatch(getLaboratoryTest({ id: idForUpdate })).then((val) => {
        if (val.type === "labTest/get-lab-test/fulfilled") {
          setSavedSchedule(val.payload.data.testDetails?.schedules);
          setLoading(false);
          setUpdateData(val.payload.data);
          setSelectedGender(val.payload.data.applicableTo);
          setSelectedTestType(val.payload.data.type);
          form.setFieldsValue({
            name: val.payload.data.name,
            code: val.payload.data.code,
            clientId: val.payload.data.clientId,
            type: val.payload.data.type,
            orderable: val.payload.data.orderable,
            applicableTo: val.payload.data.applicableTo,
            abbreviation: val.payload.data?.abbreviation,
            departmentId: val.payload.data.testDetails?.departmentId,
            methodology: val.payload.data.testDetails?.methodology,
            samplePreparation: val.payload.data.testDetails?.samplePreparation,
            cutoff: val.payload.data.testDetails?.cutoff,
            tatInHours: val.payload.data.testDetails?.tatInHours,
            isStatAllowed: val.payload.data.testDetails?.isStatAllowed,
            sendOut: val.payload.data.testDetails?.sendOut,
            labTestReqInputs: val.payload.data.testDetails?.labTestRequirements,
            maxSpecimenAgeInHours:
              val.payload.data.testDetails?.maxSpecimenAgeInHours,
            labTestSpecimenIds:
              val.payload.data?.testDetails?.labTestSpecimens.map((item) => {
                return item.id;
              }),
          });
        }
      });
    } else {
      setLabTestReqArr([]);
      clearFormInputs();
      setSavedSchedule([]);
    }

    // eslint-disable-next-line
  }, [dispatch, idForUpdate, form]);

  useEffect(() => {
    LoadData();
    LoadLabDeptData();
    if (isForUpdate) {
      LoadUpdateData();
    }
  }, [dispatch, LoadData, LoadLabDeptData, isForUpdate, LoadUpdateData]);

  const options = testTypes.map((val) => {
    return { value: val, label: val };
  });

  const onFinish = (values) => {
    setSelectedTest([]);
    if (isForUpdate) {
      // HANDLE LOADING
      if (updateLabTest.pending().type === "labTest/update-labTest/pending") {
        setLoading(true);
      }
      dispatch(
        updateLabTest([
          values,
          values.type === "Test"
            ? {
                id: idForUpdate,
                labTestId: idForUpdate,
                departmentId: values.departmentId,
                methodology: values.methodology,
                samplePreparation: values.samplePreparation,
                cutoff: values.cutoff,
                tatInHours: values.tatInHours,
                sendOut: values.sendOut ? true : false,
                isStatAllowed: values.isStatAllowed ? true : false,
                schedules: savedSchedule,
                labTestRequirements: labTestReqArr.map(
                  ({ labTestInfoId, ...rest }) => rest
                ),
                labTestSpecimenIds: values.labTestSpecimenIds,
                maxSpecimenAgeInHours: values.maxSpecimenAgeInHours,
              }
            : null,
          selectedData?.length === 0
            ? null
            : selectedData?.map((val) => {
                return val.data ? val.data.id : val.id;
              }),
          idForUpdate,
        ])
      ).then((val) => {
        if (val.payload?.success) {
          refetchLabtestData();
          setLoading(false);
          notification.success({
            message: "Laboratory Test",
            description: `Successfully updated ${val.payload.data.name}`,
          });
          handleCloseModal();
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Laboratory Test",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    } else {
      //HANDLE LOADING
      if (createLabTest.pending().type === "labTest/create-labTest/pending") {
        setLoading(true);
      }
      dispatch(
        createLabTest([
          values,
          values.type === "Test"
            ? {
                departmentId: values.departmentId,
                methodology: values.methodology,
                samplePreparation: values.samplePreparation,
                cutoff: values.cutoff,
                tatInHours: values.tatInHours,
                sendOut: values.sendOut ? true : false,
                isStatAllowed: values.isStatAllowed ? true : false,
                schedules: savedSchedule,
                labTestRequirements: labTestReqArr,
                labTestSpecimenIds: values.labTestSpecimenIds,
                maxSpecimenAgeInHours: values.maxSpecimenAgeInHours,
              }
            : null,
          selectedData.length === 0
            ? null
            : selectedData.map((val) => {
                return val.data.id;
              }),
        ])
      ).then((val) => {
        if (val.payload?.success) {
          refetchLabtestData();
          setLoading(false);
          notification.success({
            message: "Laboratory Test",
            description: `Successfully added ${val.payload.data.name}`,
          });
          handleCloseModal();
        }
        if (!val.payload?.success) {
          setLoading(false);
          notification.error({
            message: "Failed to Add Laboratory Test",
            description: errorMessagesDescription(
              val.payload?.response.data.errorMessages
            ),
          });
        }
      });
    }
  };

  const handleChangeType = (val) => {
    setSelectedTestType(val);
    setSelectedTest([]);
  };

  const handleSelectedData = (val) => {
    setSelectedData(val);
  };

  const clientOption = sortDataAlphabetically(clientData, "clientName").map(
    (item) => {
      return { value: item.id, label: item.displayName };
    }
  );

  const specimenOptions = specimen.map((item) => {
    return { value: item.id, label: item.name };
  });

  const handleSelectGender = (e) => {
    const { value } = e.target;
    if (value === 0) {
      setSelectedGender("male");
    } else if (value === 1) {
      setSelectedGender("female");
    } else {
      setSelectedGender("both");
    }
  };

  const handleDeleteLabtest = () => {
    dispatch(
      hasSecondAuthorityDeleteLabtest({
        id: idForUpdate,
      })
    ).then((response) => {
      const isFetchSuccess = response?.payload?.success;
      if (isFetchSuccess) {
        handleCloseModal();
        setSearchText("");
        notification.success({
          message: "Deleted Labtest",
          description: `${response?.payload?.successMessage}`,
        });
        refetchLabtestData();
      }
      if (!isFetchSuccess) {
        notification.error({
          message: "Failed to delete labtest",
          description: errorMessagesDescription(
            response.payload?.response?.data?.errorMessages
          ),
        });
      }
    });
  };

  return (
    <Modal
      open={openCreateUpdate}
      title={isForUpdate ? "Update Labtest" : "Create Labtest"}
      onCancel={handleCloseModal}
      footer={[
        <Button
          form="test"
          key="submit"
          htmlType="submit"
          loading={loading}
          type="primary"
          className="submitBtn"
        >
          Submit
        </Button>,
        hasSecondAuthorityClaim && isAuthorizedToDelete && isForUpdate && (
          <PopConfirm
            onConfirm={handleDeleteLabtest}
            title="Delete Labtest"
            description={
              <p>
                Are you sure you want to delete{" "}
                <strong>{updateData?.name}</strong>?
              </p>
            }
            okText="Yes"
            cancelText="No"
          >
            <Button type="primary" danger>
              Delete
            </Button>
          </PopConfirm>
        ),
        !hasSecondAuthorityClaim && isAuthorizedToDelete && isForUpdate && (
          <Button onClick={handleShowDeleteForm} type="primary" danger>
            Delete
          </Button>
        ),
      ]}
      width={600}
    >
      <Box
        sx={{
          height: enableModalScroll ? "32rem" : "auto",
          overflowY: enableModalScroll ? "scroll" : "auto",
          overflowX: "hidden",
          padding: "1rem",
        }}
      >
        <Form
          name="test"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          form={form}
        >
          {/* Code */}
          <Form.Item
            label="Code"
            name="code"
            rules={[
              {
                required: true,
                message: (
                  <i style={{ fontSize: "0.8rem" }}>
                    Please input the test code.
                  </i>
                ),
              },
            ]}
          >
            <Input disabled={isForUpdate} placeholder="Code" />
          </Form.Item>
          {/* Name */}
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: (
                  <i style={{ fontSize: "0.8rem" }}>
                    Please input the test name.
                  </i>
                ),
              },
            ]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          {/* Type */}
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: (
                  <i style={{ fontSize: "0.8rem" }}>
                    Please select the test type.
                  </i>
                ),
              },
            ]}
          >
            <Select
              placeholder="Please select labtest type"
              loading={loading}
              options={options}
              maxLength={5}
              onChange={handleChangeType}
              disabled={isForUpdate}
            />
          </Form.Item>
          {/* Gender */}
          <Form.Item
            label="Applicable to"
            name="applicableTo"
            rules={[
              {
                required: true,
                message: (
                  <i style={{ fontSize: "0.8rem" }}>
                    Please select who is applicable for this labtest.
                  </i>
                ),
              },
            ]}
          >
            <Radio.Group onChange={handleSelectGender}>
              <Radio value={2}>Both</Radio>
              <Radio value={0}>Male</Radio>
              <Radio value={1}>Female </Radio>
            </Radio.Group>
          </Form.Item>

          {/* Orderable */}
          <Form.Item label="Orderable" valuePropName="checked" name="orderable">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked
            />
          </Form.Item>

          {/* Abbreviation */}
          <Form.Item label="Abbreviation" name="abbreviation">
            <Input placeholder="Abbreviation" />
          </Form.Item>

          {/* Client */}
          <Form.Item label="Client" name="clientId">
            <Select
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              virtual={false}
              options={clientOption}
              placeholder={"Client"}
            />
          </Form.Item>

          {/* Test Selection */}
          {selectedtestType !== "Test" && updateData?.type !== "Test" && (
            <Form.Item
              label={
                selectedtestType === "Test" || selectedtestType === ""
                  ? ""
                  : "Test Selection"
              }
              name="testSelection"
              rules={[
                {
                  message: "Select atleast one test",
                  validator: () => {
                    if (selectedTest.length > 0) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Select atleast one test");
                    }
                  },
                },
              ]}
            >
              <TestSelection
                selectedGender={selectedGender}
                selectedTest={selectedTest}
                setSelectedTest={setSelectedTest}
                isForUpdate={isForUpdate}
                handleSelectedData={handleSelectedData}
                type={selectedtestType}
                currentTestUpdate={updateData?.child}
              />
            </Form.Item>
          )}
          {(selectedtestType === "Test" || updateData?.type === "Test") && (
            <>
              {/* Department */}
              <Form.Item
                label="Department"
                name="departmentId"
                rules={[
                  {
                    required: true,
                    message: (
                      <i style={{ fontSize: "0.8rem" }}>
                        Please input the test department.
                      </i>
                    ),
                  },
                ]}
              >
                <Select
                  virtual={false}
                  style={{ width: 220 }}
                  options={labDeptList}
                />
              </Form.Item>

              {/* Methodology */}
              <Form.Item
                label="Methodology"
                name="methodology"
                rules={[
                  {
                    required: true,
                    message: (
                      <i style={{ fontSize: "0.8rem" }}>
                        Please input the test methodology.
                      </i>
                    ),
                  },
                ]}
              >
                <Input placeholder="Methodology" />
              </Form.Item>

              {/* Sample Preparation */}
              <Form.Item
                label="Sample Preparation"
                name="samplePreparation"
                rules={[
                  {
                    required: true,
                    message: (
                      <i style={{ fontSize: "0.8rem" }}>
                        Please input the test sample preparation.
                      </i>
                    ),
                  },
                ]}
              >
                <Input placeholder="Sample Preparation" />
              </Form.Item>

              {/* Cut Off */}
              <Form.Item label="Cut off of acceptance" name="cutoff">
                <Select
                  style={{ width: 120 }}
                  options={weekOptions}
                  allowClear
                />
              </Form.Item>

              {/* TAT In Hours */}
              <Form.Item
                label="TAT In Hours"
                name="tatInHours"
                rules={[
                  {
                    required: true,
                    message: (
                      <i style={{ fontSize: "0.8rem" }}>
                        Please input TAT In Hours.
                      </i>
                    ),
                  },
                ]}
              >
                <InputNumber min={1} />
              </Form.Item>

              {/* Max Specimen Age In Hours */}
              <Form.Item
                label="Max Specimen Age In Hours"
                name="maxSpecimenAgeInHours"
              >
                <InputNumber min={1} />
              </Form.Item>

              {/* Send Out */}
              <Form.Item
                label="Send Out"
                valuePropName="checked"
                name="sendOut"
                initialValue={false}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>

              {/* Stat */}
              <Form.Item
                label="Allow Stat"
                valuePropName="checked"
                name="isStatAllowed"
                initialValue={false}
              >
                <Switch checkedChildren="Yes" unCheckedChildren="No" />
              </Form.Item>

              {/* Specimen */}
              <Form.Item
                label="Specimen"
                name="labTestSpecimenIds"
                rules={[
                  {
                    required: true,
                    message: (
                      <i style={{ fontSize: "0.8rem" }}>
                        Please input Specimen.
                      </i>
                    ),
                  },
                ]}
              >
                <Select
                  placeholder="Please select specimen"
                  showSearch
                  optionFilterProp="children"
                  virtual={false}
                  options={specimenOptions}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  mode="multiple"
                  allowClear
                />
              </Form.Item>
              {/* Schedules */}
              <AddSchedule
                savedSchedule={savedSchedule}
                scheduleInputs={scheduleInputs}
                handleRemoveSchedule={handleRemoveSchedule}
                handleScheduleChange={handleScheduleChange}
                handleAddSchedule={handleAddSchedule}
                filteredWeekOption={filteredWeekOption}
                handleTimePeriodChange={handleTimePeriodChange}
              />
              {/* Lab Test requirements */}
              <AddLabtestRequirements
                labTestReqArr={labTestReqArr}
                selectedReqId={selectedReqId}
                handleRemoveSavedLabTestReq={handleRemoveSavedLabTestReq}
                handleSelectReqToEdit={handleSelectReqToEdit}
                handleDiscardChanges={handleDiscardChanges}
                handleSaveLabTestReq={handleSaveLabTestReq}
                handleSelectIfRequired={handleSelectIfRequired}
                handleChangeReqDetails={handleChangeReqDetails}
                handleChangeGroupIdentifier={handleChangeGroupIdentifier}
                handleChangeIsGroup={handleChangeIsGroup}
                labTestReqInputs={labTestReqInputs}
                handleSelectReqType={handleSelectReqType}
                handleChangeRequirementTemplateId={
                  handleChangeRequirementTemplateId
                }
                setLabTestReqArr={setLabTestReqArr}
              />
            </>
          )}
        </Form>
      </Box>
      <DeleteLabtestForm
        updateData={updateData}
        labtestId={idForUpdate}
        refetchLabtestData={refetchLabtestData}
        handleCloseModal={handleCloseModal}
        handleHideDeleteForm={handleHideDeleteForm}
        showDeleteForm={showDeleteForm}
        setSearchText={setSearchText}
      />
    </Modal>
  );
};

export default CreateUpdateLabTest;
