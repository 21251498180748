import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ScienceIcon from "@mui/icons-material/Science";
import GroupsIcon from "@mui/icons-material/Groups";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import LandscapeIcon from "@mui/icons-material/Landscape";
import Groups3Icon from "@mui/icons-material/Groups3";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import { Tooltip } from "@mui/material";
import DatasetIcon from "@mui/icons-material/Dataset";
import SummarizeIcon from "@mui/icons-material/Summarize";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { Biotech } from "@mui/icons-material";
import { handleChangeFields } from "store/slice/inputSlice";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import TableViewIcon from "@mui/icons-material/TableView";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ArticleIcon from "@mui/icons-material/Article";

const MenuItemRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { claims } = useSelector((state) => state.auth);
  const { open } = useSelector((state) => state.settings);

  const handleNavigate = (routeLink) => {
    navigate(routeLink);
    if (routeLink === "/patient") {
      handleClearPatientInputFields();
    }
  };

  const handleClearPatientInputFields = () => {
    //For clearing patient's input data
    dispatch(
      handleChangeFields({
        id: 0,
        firstname: "",
        lastname: "",
        middlename: "",
        gender: "",
        dateOfBirth: null,
        contactNumber: "",
        emails: [],
        patientEmailForDisplay: [],
        medicalRecordNo: "",
        cardNumber: "",
        governmentId: "",
        phAddresses: [],
        isPatientHasAnAcceptedOrder: false,
      })
    );
    dispatch(
      handleChangeMultiFields({
        patientId: 0,
      })
    );
  };

  const elementRenderSequence = [
    //In this way we can control the arrangement of each element when displayed
    "Laboratory:SupplyOrder:Modify",
    "Laboratory:MinimalTestOrder:Read",
    "Laboratory:TestOrder:Read",
    "Laboratory:TestOrderSummary:Read",
    "Laboratory:Patient:Read",
    "Administration:Country:Read",
    "Administration:Province:Read",
    "Administration:RegisterQRCode:Read",
    "Administration:CityMunicipality:Read",
    "Administration:Barangay:Read",
    "Administration:Client:Read",
    "Administration:ClientType:Read",
    "Laboratory:LabTest:Read",
    "Laboratory:LabTest:Modify",
    "Laboratory:LabWorkload:Read",
    "Laboratory:HealthcareProvider:Read",
    "Administration:LabDepartment:Read",
    "Laboratory:ICD10Code:Read",
    "Laboratory:SampleCollector:Read",
    "Laboratory:Specimen:Read",
  ];

  const elementClaims = elementRenderSequence?.map((key) =>
    claims?.find((element) => element?.permissionKey === key)
  );
  // eslint-disable-next-line
  return elementClaims?.map((element, index) => {
    let routeName = "Route";
    let routeLink = "route";
    let routeIcon = "";
    const key = element?.permissionKey && element?.permissionKey?.split(":")[2];
    if ((key === "Modify" || key === "Read") && element?.isGranted) {
      switch (element?.permissionKey) {
        case "Laboratory:SupplyOrder:Modify":
          routeName = "Supply Request";
          routeLink = "/supply-request";
          routeIcon = <VaccinesIcon />;
          break;
        case "Laboratory:MinimalTestOrder:Read":
          routeName = "LRF Pickup Request";
          routeLink = "/lrf-pickup-request";
          routeIcon = <NoteAltIcon />;
          break;
        case "Laboratory:TestOrder:Read":
          routeName = "Test Order";
          routeLink = "/test-orders";
          routeIcon = <FactCheckIcon />;
          break;
        case "Laboratory:TestOrderSummary:Read":
          routeName = "Test Order Summary";
          routeLink = "/session-summary";
          routeIcon = <ReceiptIcon />;
          break;
        case "Laboratory:Patient:Read":
          routeName = "Patient";
          routeLink = "/patient";
          routeIcon = <PersonIcon />;
          break;
        case "Administration:Country:Read":
          routeName = "Country";
          routeLink = "/country";
          routeIcon = <PublicIcon />;
          break;
        case "Administration:Province:Read":
          routeName = "Province";
          routeLink = "/province";
          routeIcon = <LandscapeIcon />;
          break;
        case "Administration:RegisterQRCode:Read":
          routeName = "Qr Code Registration";
          routeLink = "/qr-registration";
          routeIcon = <QrCodeScannerIcon />;
          break;
        case "Administration:CityMunicipality:Read":
          routeName = "City Municipality";
          routeLink = "/city-municipality";
          routeIcon = <LocationCityIcon />;
          break;
        case "Administration:Barangay:Read":
          routeName = "Barangay";
          routeLink = "/barangay";
          routeIcon = <AccountBalanceIcon />;
          break;
        case "Administration:Client:Read":
          routeName = "Client";
          routeLink = "/client";
          routeIcon = <GroupsIcon />;
          break;
        case "Administration:ClientType:Read":
          routeName = "Client Type";
          routeLink = "/client-type";
          routeIcon = <Groups3Icon />;
          break;
        case "Laboratory:LabTest:Read":
          routeName = "Laboratory Test";
          routeLink = "/labtest";
          routeIcon = <Biotech />;
          break;
        case "Laboratory:LabTest:Modify":
          routeName = "Requirement Template";
          routeLink = "/labtest-requirement-template";
          routeIcon = <ArticleIcon />;
          break;
        case "Laboratory:LabWorkload:Read":
          routeName = "Lab Workload";
          routeLink = "/lab-workload";
          routeIcon = <TableViewIcon />;
          break;
        case "Laboratory:HealthcareProvider:Read":
          routeName = "Healthcare Provider";
          routeLink = "/healthcare-provider";
          routeIcon = <HealthAndSafetyIcon />;
          break;
        case "Administration:LabDepartment:Read":
          routeName = "Department";
          routeLink = "/department";
          routeIcon = <AccountTreeIcon />;
          break;
        case "Laboratory:ICD10Code:Read":
          routeName = "ICD";
          routeLink = "/icd-codes";
          routeIcon = <DatasetIcon />;
          break;
        case "Laboratory:SampleCollector:Read":
          routeName = "Sample Collector";
          routeLink = "/sample-collector";
          routeIcon = <SummarizeIcon />;
          break;
        case "Laboratory:Specimen:Read":
          routeName = "Specimen";
          routeLink = "/specimen";
          routeIcon = <ScienceIcon />;
          break;
        default:
          break;
      }
      if (routeName !== "Route") {
        return (
          <Tooltip key={index} title={!open ? routeName : ""}>
            <ListItemButton
              onClick={() => {
                handleNavigate(routeLink);
              }}
              selected={routeLink === location.pathname}
            >
              <ListItemIcon>{routeIcon}</ListItemIcon>
              <ListItemText primary={routeName} />
            </ListItemButton>
          </Tooltip>
        );
      }
    }
  });
};
export default MenuItemRoute;
