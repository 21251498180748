import useDebounce from "hooks/useDebounce";
import { useCallback, useEffect, useState } from "react";
import { getAllRequirementTemplate } from "store";
import { getRequirementType } from "../labtest/data";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { Button as BtnAntD, ConfigProvider, Input, Select, Table } from "antd";
import { useCurrentHeight } from "utils/getScreenSize";
import { useDispatch } from "react-redux";
import { EmptyData } from "components/mnl/global/emptyData";
import { Button, Grid } from "@mui/material";
import CreateUpdateLabtestRequirementModal from "./createUpdateLabtestRequirementModal";

const GetAllLabtestRequirementTemplate = () => {
  const [searchText, setSearchText] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortField, setSortField] = useState("Id");
  const [sortDirection, setSortDirection] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [requirementTemplateData, setRequirementTemplateData] = useState([]);
  const [
    openCreateUpdateRequirementModal,
    setOpenCreateUpdateRequirementModal,
  ] = useState(false);
  const [isDataForUpdate, setIsDataForUpdate] = useState(false);
  const [dataForUpdate, setDataForUpdate] = useState(null);

  const dispatch = useDispatch();
  const debouncedInputValue = useDebounce(searchText);

  const LoadData = useCallback(() => {
    if (
      getAllRequirementTemplate.pending().type ===
      "lab-requirements/get-all-requirement-template/pending"
    ) {
      setIsLoading(true); //To check if the get request is pending
    }

    dispatch(
      getAllRequirementTemplate({
        search: debouncedInputValue,
        pageSize: pageSize,
        pageNumber: pageNumber,
        sortField: sortField,
        sortDirection: sortDirection,
      }) //Fetching of all the labtest requirement template from api
    ).then((element) => {
      if (
        element.type ===
        "lab-requirements/get-all-requirement-template/fulfilled"
      ) {
        setIsLoading(false);
        setRequirementTemplateData(element?.payload?.data?.items);
        setTotal(element?.payload?.data?.totalPages * pageSize);
      } // Getting the response and calculates the total page that will be displayed in the table
    });
  }, [
    dispatch,
    debouncedInputValue,
    pageSize,
    sortField,
    pageNumber,
    sortDirection,
  ]);

  useEffect(() => {
    LoadData(); //Invoking of get requirement template request
  }, [dispatch, LoadData]);

  const onPageChange = (page) => {
    //To get the current page of the labtest
    setPageNumber(page);
    setIsLoading(true);
  };

  const onShowPageSizeChange = (_, pageSize) => {
    //To get the page size of the labtest
    setPageSize(pageSize);
  };

  const handleChangeSearch = (val) => {
    //This is where the filtering of search in table happens
    setIsLoading(true);
    setSearchText(val.target.value);
    setPageSize(1);
  };

  const handleChangeSortField = (value) => {
    //For sort field filtering in requirement template table
    setSortField(value);
  };

  const handleChangeSortDirection = (value) => {
    //For sort direction filtering in requirement template if it will be ascending or descending
    setSortDirection(value);
  };

  const renderEmptyData = () => (
    <EmptyData description="No Requirement Template Detected" />
  );

  const columns = [
    //Labtest table column
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 60,
    },
    {
      title: "Requirement Details",
      dataIndex: "requirementDetails",
      key: "requirementDetails",
    },
    {
      title: "Requirement Type",
      dataIndex: "requirementType",
      key: "requirementType",
      render: (value) => getRequirementType(value).requirementType,
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (value) => moment(value).format("ll"),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <>
          <BtnAntD
            size="small"
            type="text"
            style={{
              marginLeft: 2,
            }}
            icon={<EditIcon fontSize="inherit" />}
            className="bg-orange-600 text-white hover:enabled:bg-orange-400 hover:enabled:text-white"
            onClick={() => {
              setOpenCreateUpdateRequirementModal(true);
              setIsDataForUpdate(true);
              setDataForUpdate(record);
            }}
          >
            Update
          </BtnAntD>
        </>
      ),
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Input
            placeholder="Search Test"
            prefix={<SearchOutlined />}
            onChange={handleChangeSearch}
            value={searchText}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Select
            className="w-28"
            defaultValue={sortField}
            onChange={handleChangeSortField}
            options={[
              {
                value: "Id",
                label: "Id",
              },
            ]}
          />
          <Select
            defaultValue={sortDirection}
            onChange={handleChangeSortDirection}
            style={{
              marginLeft: 2,
            }}
            options={[
              {
                value: 0,
                label: "Ascending",
              },
              {
                value: 1,
                label: "Descending ",
              },
            ]}
          />
        </Grid>
        <Grid item xs={12} md={2} lg={2}>
          <Button
            className="w-max"
            startIcon={<AddOutlinedIcon />}
            variant="text"
            onClick={() => setOpenCreateUpdateRequirementModal(true)}
          >
            New Requirement Template
          </Button>
        </Grid>
      </Grid>
      <ConfigProvider renderEmpty={renderEmptyData}>
        <Table
          rowClassName="odd:bg-slate-300"
          columns={columns}
          dataSource={requirementTemplateData}
          loading={isLoading}
          size="small"
          pagination={{
            total: total,
            showSizeChanger: true,
            onShowSizeChange: onShowPageSizeChange,
            current: pageNumber,
            onChange: onPageChange,
            showQuickJumper: true,
            position: ["bottomRight"],
          }}
          bordered={true}
          scroll={{ y: useCurrentHeight() - 330 }}
          style={{ marginTop: 10 }}
        />
      </ConfigProvider>
      <CreateUpdateLabtestRequirementModal
        open={openCreateUpdateRequirementModal}
        onCancel={() => {
          setOpenCreateUpdateRequirementModal(false);
          setIsDataForUpdate(false);
          setDataForUpdate(null);
        }}
        refetchData={LoadData}
        isDataForUpdate={isDataForUpdate}
        dataForUpdate={dataForUpdate}
      />
    </>
  );
};

export default GetAllLabtestRequirementTemplate;
