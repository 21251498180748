import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import TestModal from "./labtestModal/testModal";
import { useDispatch, useSelector } from "react-redux";
import { getLabTestRequirements } from "store";
import SelectedLabtestTable from "./selectedLabtestTable";
import LabtestSearchInputs from "./labtestSearchInputs";
import ProfilePanelPackageSpecimenSelectionModal from "./labtestModal/profilePanelPackageSpecimenSelectorModal";
import useAdditionalInfoFunctions from "./labtestModal/useAdditionalInfoFunctions";
import useFetchSpecificLabtest from "components/mnl/testRequest/proxies/useFetchSpecificLabtest";
import { handleChangeSavedFields } from "store/slice/savedInputSlice";
import getDuplicatedSpecimenNames from "components/mnl/testRequest/proxies/getDuplicatedSpecimenNames";
import getSpecimenRecursively from "components/mnl/testRequest/proxies/getSpecimenRecursively";
import getLabtestIdRecursively from "components/mnl/testRequest/proxies/getLabtestIdRecursively";
import getLabtestRequirementsRecursively from "components/mnl/testRequest/proxies/getLabtestRequirementsRecursively";
import getSpecimenOptionsRecursively from "components/mnl/testRequest/proxies/getSpecimenOptionsRecursively";
import errorMessagesDescription from "components/mnl/global/errorMessagesDescription";
import AdditionalPatientInfoModal from "./labtestModal/additionalPatientInfoModal";
import TestProfileInfoDetails from "./labtestModal/testProfileInfoDetails";
import ResultComponent from "components/mnl/global/resultComponent";
import LoadingSpinner from "components/mnl/global/loadingSpinner";
import { showToastNotification } from "utils/showToastNotification";
import moment from "moment";
import { getLabRequirementRequireValue } from "../../proxies/getLabRequirementRequireValue";

const FirstSection = () => {
  const { savedLabtests, multiStepFormInputs, orderData } = useSelector(
    (state) => ({
      savedLabtests: state.savedLabtests,
      multiStepFormInputs: state.multiStepFormInputs,
      orderData: state.orderData,
    })
  );
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [testInput, setTestInput] = useState("");
  const [fetchedInputFields, setFetchedInputFields] = useState(null);
  const [inputValues, setInputValues] = useState([]);
  const [selectedTestDatas, setSelectedTestDatas] = useState(null);
  const [selectedTestProfileData, setSelectedTestProfileData] = useState([]);
  const [showMultiSpecimenSelectorModal, setShowMultiSpecimenSelectorModal] =
    useState(false);
  const [showMultiLabRequirementsModal, setShowMultiLabRequirementsModal] =
    useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showSpecimenModal, setShowSpecimenModal] = useState(false);
  const [selectedSpecimenType, setSelectedSpecimenType] = useState("");
  const [specimenOptions, setSpecimenOptions] = useState([]);
  const { testData, isTestLoading } = useFetchSpecificLabtest(
    selectedTestProfileData.id
  );
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningMessage, setWarningMessage] = useState(null);
  const [
    profilePackagePanelLabRequirements,
    setProfilePackagePanelLabRequirements,
  ] = useState([]);
  const orderStatusThatHasUpdateCondition =
    orderData.orderStatus.toLowerCase() === "completeddelivery" ||
    orderData.orderStatus.toLowerCase() === "intransit" ||
    orderData.orderStatus.toLowerCase() === "pickedup";
  const dispatch = useDispatch();

  const sortedInputValues = inputValues?.sort((a, b) =>
    a?.groupIdentifier?.localeCompare(b?.groupIdentifier)
  );

  const {
    checkLabtestState,
    filteredLabtestForPickedup,
    getPatientSpecimensData,
    getLabtestIdAndSpecimen,
    getNestedLabtestIdAndSpecimen,
    getNestedPatientSpecimensData,
    getNestedTestOrderDetails,
    getTestOrderDetails,
    getLabtestData,
  } = useAdditionalInfoFunctions();

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseAdditionalPatientInfoModal = () => {
    //Function for closing additional patient information modal
    const clearedInputValues = inputValues.map((_, index) => ({
      id: index,
      value: "",
      description: "",
      isRequired: false,
    }));
    setInputValues(clearedInputValues);
    setIsOpen(false);
    setFetchedInputFields(null);
  };

  const handleCloseMultiSpecimenSelectorModal = () => {
    setShowMultiSpecimenSelectorModal(false);
  };

  const handleCloseMultiLabRequirementsModal = () => {
    setShowMultiLabRequirementsModal(false);
  };

  const handleOpenTestModal = (selectedTest) => {
    //Function for opening labtest information modal
    setSelectedTestProfileData(selectedTest);
    setSelectedSpecimenType(selectedTest.type);
    setShowTestModal(true);
  };

  const handleCloseTestModal = () => {
    //Function for closing labtest information modal
    setSelectedTestProfileData([]);
    setShowTestModal(false);
    setSelectedSpecimenType("");
  };

  const handleShowWarningModal = (message) => {
    //For showing warning message modal
    setIsLoading(false);
    setShowWarningModal(true);
    setWarningMessage(message);
    const modalCloseTimeout = setTimeout(() => {
      setShowWarningModal(false);
      setWarningMessage(null);
    }, 4000);

    return () => clearTimeout(modalCloseTimeout);
  };

  const handleCloseWarningModal = () => {
    //For closing warning message modal
    setShowWarningModal(false);
    setWarningMessage(null);
  };

  const resetInputAndLoadingState = () => {
    setTestInput("");
    setIsLoading(false);
  };

  const showAlreadySavedWarning = (selectedLabTest) => {
    setIsLoading(false);
    handleShowWarningModal(
      <p style={{ fontSize: "1rem", color: "black" }}>
        <strong style={{ color: "red" }}>{selectedLabTest.name}</strong> is
        already added
      </p> //Display a notification if it is already added
    );
  };

  const showIncludedInParentWarning = (selectedLabTest, parentLabtestNames) => {
    setIsLoading(false);
    handleShowWarningModal(
      <p style={{ fontSize: "1rem", color: "black" }}>
        <strong style={{ color: "red" }}> {selectedLabTest.name}</strong> is
        already included in{" "}
        <strong style={{ color: "green" }}>
          {parentLabtestNames.map((test) => test.name).join(", ")}
        </strong>
      </p> //Display a notification if it is already added
    );
  };

  const showEmptyLabtestWarning = (labtestName) => {
    showToastNotification(
      "error",
      `Unable to add ${labtestName}`,
      " The selected test/profile does not have a configured specimen. Please contact ABC Lab."
    );
  };

  const showExceededSpecimenWarning = (responseData) => {
    if (filteredLabtestForPickedup(responseData)) {
      return handleShowWarningModal(
        <p style={{ fontSize: "1rem", color: "black" }}>
          Adding <strong style={{ color: "red" }}>{responseData.name}</strong>{" "}
          will exceed the required maximum number of tests allowed for the
          provided specimen.
        </p> //Display a notification if the test will exceed the current max test of specimen
      );
    }
  };

  //Adding labtest in list
  const handleAddLabtest = (selectedLabTest) => {
    resetInputAndLoadingState();
    const { isAlreadySaved, isInsideOfParent, parentLabtestNames } =
      checkLabtestState(selectedLabTest);

    if (isAlreadySaved) {
      //Checking if selected test is already added
      setIsLoading(false);
      showAlreadySavedWarning(selectedLabTest);
    }

    if (isInsideOfParent) {
      //Check if the added labtest is present in profile/panel/package
      setIsLoading(false);
      showIncludedInParentWarning(selectedLabTest, parentLabtestNames);
    }

    if (!isAlreadySaved && !isInsideOfParent) {
      //If the labtest can be added
      dispatch(
        handleChangeSavedFields({
          isTestProfileEmpty: false,
          isSampleCollectorEmpty: false,
          isSpecimenQuantityEmpty: false,
          isCollectionSiteEmpty: false,
        })
      );
      getSelectedLabtestDataFromApi(selectedLabTest); //Get all the data of the labtest that will be added
    }
  };

  const getSelectedLabtestDataFromApi = async (selectedLabTest) => {
    const responseData = await fetchSelectedLabtestDetails(selectedLabTest?.id);
    const isLabtestSpecimenEmpty =
      responseData?.testDetails?.labTestSpecimens?.length === 0;

    const {
      isSpecimenCanBeAdded,
      singleTestHasAdditionalInformation,
      singleTestHasMultipleSpecimen,
    } = checkLabtestState(responseData);

    setSelectedTestDatas(responseData);

    if (isLabtestSpecimenEmpty) {
      showEmptyLabtestWarning(selectedLabTest.name);
    }

    if (orderStatusThatHasUpdateCondition) {
      showExceededSpecimenWarning(responseData);
    }

    const {
      duplicatedSpecimenNames,
      specimensToBeAdded,
      testIdsToBeAdded,
      childLabTestRequirements,
      recursiveSpecimenOptions,
    } = getNestedDatas(responseData);

    const parentLabtestHasLabRequirements = childLabTestRequirements.length > 0;
    const filteredSavedSelectedIds =
      savedLabtests?.savedLabtestsIdAndSpecimen?.filter(
        (data) =>
          !testIdsToBeAdded.map((data) => data.testId).includes(data.testId)
      ); //Filter recursively fetched labtest id, check if one of the labtest is already added

    const filteredSavedTest = savedLabtests?.savedLabtestsData?.filter(
      (data) => !testIdsToBeAdded.map((data) => data.testId).includes(data.id)
    ); //Filtered recursively fetched labtest if it is already added

    const listsOfLabtestThatWillBeRemoved =
      savedLabtests?.savedLabtestsData?.filter((data) =>
        testIdsToBeAdded.map((data) => data.testId)?.includes(data.id)
      ); //Get all of the labtest that needs to be removed, before we add panel/profile/package

    const labtestsToBeRemovedInTestOrder =
      multiStepFormInputs.testOrderDetails?.filter(
        (data) =>
          !testIdsToBeAdded.map((data) => data.testId).includes(data.testId)
      ); //Labtests that will be removed in test order details

    const profilePackagePanelHasMultipleSpecimen =
      selectedLabTest.type !== "Test" && recursiveSpecimenOptions?.length > 0;

    if (!parentLabtestHasLabRequirements) {
      //Check if the panel/profile/package test doesn't have any additional patient information
      getNestedLabtestIdAndSpecimen(filteredSavedSelectedIds, testIdsToBeAdded);
    }
    if (
      singleTestHasAdditionalInformation ||
      singleTestHasMultipleSpecimen ||
      parentLabtestHasLabRequirements ||
      profilePackagePanelHasMultipleSpecimen
    ) {
      //Check if labtest has additional patient information, it has multiple specimens and its child also has additional patient information
      if (
        parentLabtestHasLabRequirements &&
        !profilePackagePanelHasMultipleSpecimen
      ) {
        //Check if the child of added package/panel/profile labtest has additional patient information
        //Then get all of its labtest requirements
        setFetchedInputFields(childLabTestRequirements);
        getNestedLabtestRequirements(childLabTestRequirements);

        setShowMultiLabRequirementsModal(true);
      } else if (
        !parentLabtestHasLabRequirements &&
        profilePackagePanelHasMultipleSpecimen
      ) {
        getNestedSpecimenOptions(recursiveSpecimenOptions);
        setShowMultiSpecimenSelectorModal(true);
      } else if (
        parentLabtestHasLabRequirements &&
        profilePackagePanelHasMultipleSpecimen
      ) {
        getNestedLabtestRequirements(childLabTestRequirements);
        getNestedSpecimenOptions(recursiveSpecimenOptions);
        setFetchedInputFields(childLabTestRequirements);
        setShowMultiSpecimenSelectorModal(true);
        setShowMultiLabRequirementsModal(true);
      } else if (
        selectedLabTest.type === "Test" &&
        (singleTestHasAdditionalInformation || singleTestHasMultipleSpecimen)
      ) {
        //If it is a type of test, then just get the response
        setShowSpecimenModal(true);
        setFetchedInputFields({
          ...responseData,
          testDetails: {
            ...responseData?.testDetails,
            labTestRequirements: responseData?.testDetails?.labTestRequirements
              ?.map((item) => ({
                ...item,
                isRequired: getLabRequirementRequireValue(
                  responseData?.testDetails?.labTestRequirements,
                  item?.groupIdentifier,
                  item?.isRequired
                )?.requireValue,
              }))
              .sort((a, b) =>
                a?.groupIdentifier?.localeCompare(b?.groupIdentifier)
              ),
          },
        });
        getLabtestRequirements(responseData);
        getSpecimenOptions(responseData);
        handleOpenModal();
      }

      setTestInput("");
    } else {
      //If the added labtest is a type of test, then use the default response and add that to the test order details
      getTestOrderDetails(
        responseData,
        testIdsToBeAdded,
        duplicatedSpecimenNames,
        specimensToBeAdded
      );

      //If the labtest doesn't have a child, these are the data that are being saved
      const testDataToBeAdded = {
        id: responseData.id,
        type: selectedLabTest.type,
        testIds:
          responseData?.child?.length > 0
            ? testIdsToBeAdded?.map((test) => test.testId)
            : null,
        name: responseData.name,
        code: responseData.code,
        specimenExpirationInHours:
          responseData?.testDetails?.maxSpecimenAgeInHours,
        specimen:
          responseData?.child?.length > 0
            ? [...new Set(specimensToBeAdded?.map((data) => data?.specimen))]
            : [
                responseData?.testDetails?.labTestSpecimens
                  ?.map((data) => data.name)
                  .toString(),
              ],
        isStatAllowed: responseData?.testDetails?.isStatAllowed,
      };

      getLabtestData(filteredSavedTest, testDataToBeAdded);
      getNestedTestOrderDetails(
        labtestsToBeRemovedInTestOrder,
        responseData,
        testIdsToBeAdded,
        duplicatedSpecimenNames,
        specimensToBeAdded
      );

      if (listsOfLabtestThatWillBeRemoved.length > 0) {
        //Labtest that will be removed, because it is inside of package/profile/panel that is newly added
        handleShowWarningModal(
          <p style={{ fontSize: "1rem", color: "black" }}>
            <strong style={{ color: "red" }}>
              {" "}
              {listsOfLabtestThatWillBeRemoved
                ?.map((test) => test.name)
                .join(", ")}
            </strong>{" "}
            {listsOfLabtestThatWillBeRemoved?.length > 1 ? "are" : "is"} removed
            because it is included in{" "}
            <strong style={{ color: "green" }}>{selectedLabTest.name}</strong>
          </p>
        );
      }

      if (responseData?.child.length > 0) {
        //Check if the response has child
        const filteredSpecimensToAdd = specimensToBeAdded?.filter(
          (specimen) => {
            return !multiStepFormInputs?.patientSpecimens?.some((data) =>
              specimen.specimen
                .toString()
                .toLowerCase()
                .includes(data.specimen.toString().toLowerCase())
            );
          }
        ); //Filter the recursively fetched specimens
        if (filteredSpecimensToAdd.length > 0) {
          //Only add the specimen that is not present in patientSpecimens property
          getNestedPatientSpecimensData(
            filteredSpecimensToAdd,
            duplicatedSpecimenNames
          );
        }
      } else {
        if (isSpecimenCanBeAdded) {
          getPatientSpecimensData(responseData);
        }
        getLabtestIdAndSpecimen(responseData);
      }
      //After adding a labtest, success toast notification is displayed saying it is added successfully
      showToastNotification(
        "success",
        "Added Laboratory Test",
        `Successfully added ${selectedLabTest.name}`
      );
    }
  };

  const getNestedDatas = (responseData) => {
    const duplicatedSpecimenNames = getDuplicatedSpecimenNames(responseData); //Recursively fetched unflitered specimens, it means that they are duplicated

    const specimensToBeAdded = getSpecimenRecursively(responseData); //Recursively fetched filtered specimens, duplicates are removed

    const testIdsToBeAdded = getLabtestIdRecursively(responseData);

    const nestedLabtestRequirements =
      getLabtestRequirementsRecursively(responseData); //Recursively fetched additional patient information that is inside of panel/profile/package

    setProfilePackagePanelLabRequirements(
      nestedLabtestRequirements?.sort((a, b) =>
        a?.groupIdentifier?.localeCompare(b?.groupIdentifier)
      )
    );

    const transformedChildLabTestRequirementsData = nestedLabtestRequirements
      ?.map((item) => ({
        ...item,
        isRequired: getLabRequirementRequireValue(
          nestedLabtestRequirements,
          item?.groupIdentifier,
          item?.isRequired
        )?.requireValue,
      }))
      .sort((a, b) => a?.groupIdentifier?.localeCompare(b?.groupIdentifier));

    const recursiveSpecimenOptions =
      getSpecimenOptionsRecursively(responseData); //Recursively fetched specimen options

    return {
      duplicatedSpecimenNames,
      specimensToBeAdded,
      testIdsToBeAdded,
      childLabTestRequirements: transformedChildLabTestRequirementsData,
      recursiveSpecimenOptions,
    };
  };

  const fetchSelectedLabtestDetails = async (selectedLabTestId) => {
    const response = await dispatch(
      getLabTestRequirements({
        id: selectedLabTestId,
      })
    );
    const responsePayload = response?.payload;

    if (!responsePayload?.success) {
      //If the request fails, error toast notification is displayed
      showToastNotification(
        "error",
        "Failed to add Laboratory Test",
        `${errorMessagesDescription(
          response?.payload?.response.data.errorMessages
        )}`
      );
    }
    setIsLoading(false);
    return responsePayload?.data;
  };

  const getSpecimenOptions = (responseData) => {
    setSpecimenOptions(
      responseData?.testDetails?.labTestSpecimens?.map((data) => ({
        requiresSampleOrigin: data?.requiresSampleOrigin,
        requiresBloodDraw: data?.requiresBloodDraw,
        specimen: data?.name,
        key: data?.name,
        unitOfMeasure: data?.unitOfMeasure,
        specimenId: data?.id,
        specimenExpirationInHours:
          responseData?.testDetails?.maxSpecimenAgeInHours,
        specimenQuantity: data.testVolumeOrSizeRequirements.find((data) =>
          Math.min(data.minTest)
        )?.minVolume,
        notes: "",
        specimenVolReqData: data.testVolumeOrSizeRequirements,
        collectionDateTime: moment().format(),
        collectorId: data?.requiresBloodDraw ? "" : null,
        collectionSite: data?.requiresSampleOrigin ? "" : null,
        status: data.status,
      }))
    );
  };

  const getNestedSpecimenOptions = (recursiveSpecimenOptions) => {
    setSpecimenOptions(
      recursiveSpecimenOptions?.map((data) => ({
        labTestInfoId: data.labTestInfoId,
        requiresSampleOrigin: data?.requiresSampleOrigin,
        requiresBloodDraw: data?.requiresBloodDraw,
        specimen: data?.specimen,
        key: data?.key,
        specimenExpirationInHours: data?.specimenExpirationInHours,
        unitOfMeasure: data?.unitOfMeasure,
        specimenId: data?.specimenId,
        specimenQuantity: data?.specimenQuantity,
        notes: "",
        specimenVolReqData: data.specimenVolReqData,
        collectionDateTime: moment().format(),
        collectorId: data?.collectorId,
        collectionSite: data?.collectionSite,
        status: data?.status,
      }))
    );
  };

  const getLabtestRequirements = (responseData) => {
    setInputValues(
      responseData?.testDetails?.labTestRequirements?.map((field) => {
        const patientTestRequirementData =
          multiStepFormInputs?.testOrderDetails?.map(
            (item) =>
              item?.patientTestRequirementDatas?.length > 0 &&
              item?.patientTestRequirementDatas[0]
          );
        const mappedPatientRequirementDataDetails = patientTestRequirementData
          ?.map((item) => item?.patientRequirementDataDetails)
          ?.flat();

        const matchedValues =
          mappedPatientRequirementDataDetails?.length > 0 &&
          mappedPatientRequirementDataDetails?.find(
            (item) =>
              item?.requirementTemplateId === field?.requirementTemplateId
          )?.dataValue;

        return {
          id: field.id,
          groupIdentifier: field.groupIdentifier,
          requirementTemplateId: field.requirementTemplateId,
          labTestInfoId: field.labTestInfoId,
          isRequired: getLabRequirementRequireValue(
            responseData?.testDetails?.labTestRequirements,
            field.groupIdentifier,
            field.isRequired
          )?.requireValue,
          value: matchedValues
            ? matchedValues
            : field.requirementType === 3
            ? "false"
            : "",
          description: field.requirementDetails,
        };
      })
    );
  };

  const getNestedLabtestRequirements = (childLabTestRequirements) => {
    setInputValues(
      childLabTestRequirements?.map((field, index) => {
        const patientTestRequirementData =
          multiStepFormInputs?.testOrderDetails?.map(
            (item) =>
              item?.patientTestRequirementDatas?.length > 0 &&
              item?.patientTestRequirementDatas[0]
          );

        const mappedPatientRequirementDataDetails = patientTestRequirementData
          ?.map((item) => item?.patientRequirementDataDetails)
          ?.flat();

        const matchedValues =
          mappedPatientRequirementDataDetails?.length > 0 &&
          mappedPatientRequirementDataDetails?.find(
            (item) =>
              item?.requirementTemplateId === field?.requirementTemplateId
          )?.dataValue;

        return {
          id: index + 1,
          groupIdentifier: field.groupIdentifier,
          requirementTemplateId: field.requirementTemplateId,
          labTestInfoId: field.labTestInfoId,
          isRequired: getLabRequirementRequireValue(
            childLabTestRequirements,
            field.groupIdentifier,
            field.isRequired
          )?.requireValue,
          value: matchedValues
            ? matchedValues
            : field.requirementType === 3
            ? "false"
            : "",
          description: field.requirementDetails,
        };
      })
    );
  };

  return (
    <Grid sx={{ color: "grayishBlue.light" }} container>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
        <Typography
          sx={{ color: "darkBlue.main", fontWeight: "600", fontSize: "0.9rem" }}
          variant="p"
          className="required"
        >
          Add tests / profiles
        </Typography>
      </Grid>
      <Box
        sx={{
          backgroundColor: "grayishBlue.main",
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          padding: "1.3rem 1.7rem",
          marginTop: "0.5rem",
        }}
      >
        <LabtestSearchInputs
          handleAddLabtest={handleAddLabtest}
          testInput={testInput}
          setTestInput={setTestInput}
        />
        <SelectedLabtestTable handleOpenTestModal={handleOpenTestModal} />
      </Box>
      <TestModal isOpen={isOpen || showSpecimenModal}>
        <AdditionalPatientInfoModal
          setShowSpecimenModal={setShowSpecimenModal}
          specimenOptions={specimenOptions}
          showSpecimenModal={showSpecimenModal}
          setTestInput={setTestInput}
          selectedTestDatas={selectedTestDatas}
          inputValues={sortedInputValues}
          setInputValues={setInputValues}
          handleCloseAdditionalPatientInfoModal={
            handleCloseAdditionalPatientInfoModal
          }
          fetchedInputFields={fetchedInputFields}
        />
      </TestModal>
      <TestModal
        isOpen={showMultiSpecimenSelectorModal || showMultiLabRequirementsModal}
      >
        <ProfilePanelPackageSpecimenSelectionModal
          getLabtestData={getLabtestData}
          getNestedPatientSpecimensData={getNestedPatientSpecimensData}
          getNestedTestOrderDetails={getNestedTestOrderDetails}
          getNestedLabtestIdAndSpecimen={getNestedLabtestIdAndSpecimen}
          specimenOptions={specimenOptions}
          selectedTestDatas={selectedTestDatas}
          inputValues={sortedInputValues}
          setInputValues={setInputValues}
          fetchedInputFields={fetchedInputFields}
          handleCloseMultiLabRequirementsModal={
            handleCloseMultiLabRequirementsModal
          }
          handleCloseMultiSpecimenSelectorModal={
            handleCloseMultiSpecimenSelectorModal
          }
          handleCloseAdditionalPatientInfoModal={
            handleCloseAdditionalPatientInfoModal
          }
          profilePackagePanelLabRequirements={
            profilePackagePanelLabRequirements
          }
        />
      </TestModal>
      <TestProfileInfoDetails
        selectedSpecimenType={selectedSpecimenType}
        handleOpenTestModal={handleOpenTestModal}
        handleCloseTestModal={handleCloseTestModal}
        showTestModal={showTestModal}
        testData={testData}
        isTestLoading={isTestLoading}
      />

      <ResultComponent
        onCancel={handleCloseWarningModal}
        modalWidth={550}
        status="warning"
        title="Unable to add Labtest"
        subTitle={warningMessage}
        buttons={null}
        openModal={showWarningModal}
      />
      <LoadingSpinner open={isLoading} />
    </Grid>
  );
};

export default FirstSection;
