import React, { useState, useEffect } from "react";
import { Input } from "antd";
import {
  Box,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Zoom,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import moment from "moment";
import useCheckProvidedDate from "hooks/useCheckProvidedDate";
import { handleChangeSpecimenFields } from "store/slice/specimenInputSlice";
import { handleChangeSavedTestInfo } from "store/slice/savedTestInputsInfo";
import { getSampleCollectorLookUp } from "store";
import { handleChangeMultiFields } from "store/slice/multiStepFormInputSlice";
import SpecimenNoteModal from "./labtestModal/specimenNoteModal";
import { parsedClientDetails } from "utils/parsedClientDetails";

const SelectedSpecimenTable = () => {
  const multiStepFormInputs = useSelector((state) => state.multiStepFormInputs);
  const specimenInputs = useSelector((state) => state.specimenInputs);
  const savedInputs = useSelector((state) => state.savedInputs);
  const savedLabtests = useSelector((state) => state.savedLabtests);
  const dispatch = useDispatch();
  const [sampleCollectorOption, setSampleCollectorOption] = useState([]);
  const orderData = useSelector((state) => state.orderData);
  const [showSpecimenNoteModal, setShowSpecimenNoteModal] = useState(false);
  const [selectedSpecimenData, setSelectedSpecimenData] = useState({
    specimenName: "",
    specimenId: null,
    noteValue: "",
  });
  const orderStatusThatHasUpdateCondition =
    orderData.orderStatus.toLowerCase() === "completeddelivery" ||
    orderData.orderStatus.toLowerCase() === "intransit" ||
    orderData.orderStatus.toLowerCase() === "pickedup";

  const { checkIfProvidedDateInFuture } = useCheckProvidedDate();
  const { enableCollectionSite } = parsedClientDetails();

  useEffect(() => {
    //Check if specimens collection site is empty
    if (specimenInputs.collectionSite === "") {
      dispatch(
        handleChangeSpecimenFields({
          collectionSite: "",
        })
      );

      dispatch(
        handleChangeSavedTestInfo({
          isSampleOriginInputEmpty: false,
        })
      );
    }
  }, [dispatch, specimenInputs.collectionSite]);

  useEffect(() => {
    dispatch(getSampleCollectorLookUp()).then((res) => {
      setSampleCollectorOption(res?.payload?.data?.items);
    }); //Fetching of sample collector option
  }, [savedInputs.refetchData, multiStepFormInputs.patientSpecimens, dispatch]);

  //For fetching other specimen details that will be used for filtering
  useEffect(() => {
    if (orderData.isOrderDataForUpdate) {
      dispatch(
        handleChangeMultiFields({
          patientSpecimens: multiStepFormInputs?.patientSpecimens?.map(
            (data) => ({
              ...data,
              requiresBloodDraw: data.collectorId === null ? false : true,
              requiresSampleOrigin: data.collectionSite === null ? false : true,
              specimenExpirationInHours: Math.min(
                savedLabtests?.savedLabtestsIdAndSpecimen?.find((test) =>
                  test?.specimen?.includes(data?.specimen)
                )?.specimenExpirationInHours
              ),
            })
          ),
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, orderData.isOrderDataForUpdate]);

  const handleOpenSpecimenNoteModal = (specimen) => {
    setShowSpecimenNoteModal(true);
    setSelectedSpecimenData({
      specimenName: specimen.specimen,
      specimenId: specimen.specimenId,
      noteValue: "",
    });
  };

  const handleEditSpecimenNote = (specimen) => {
    setShowSpecimenNoteModal(true);
    setSelectedSpecimenData({
      specimenName: specimen.specimen,
      specimenId: specimen.specimenId,
      noteValue: specimen.notes,
    });
  };

  const handleCloseSpecimenNoteModal = () => {
    setShowSpecimenNoteModal(false);
    setSelectedSpecimenData({
      specimenName: "",
      specimenId: null,
      noteValue: "",
    });
  };

  const handleChangeSpecimenQuantity = (e, specimenId) => {
    //Changing of specimen quantity value
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs.patientSpecimens.map((specimen) =>
          specimen.specimenId === specimenId
            ? {
                ...specimen,
                specimenQuantity: e.target.value + "",
              }
            : specimen
        ),
      })
    );
  };

  const handleEditCollectionDateTime = (dateTimeValue, specimenId) => {
    //Changing of collection date time value
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs?.patientSpecimens?.map((data) =>
          data.specimenId === specimenId
            ? {
                ...data,
                collectionDateTime: moment(dateTimeValue).format(),
              }
            : data
        ),
      })
    );
  };

  const handleEditSampleCollector = (e, specimenId, index) => {
    //Changing of sample collector value
    const { value } = e.target;
    if (index === 0) {
      dispatch(
        handleChangeMultiFields({
          patientSpecimens: multiStepFormInputs.patientSpecimens.map(
            (specimen) =>
              specimen.requiresBloodDraw
                ? {
                    ...specimen,
                    collectorId: value,
                  }
                : specimen
          ),
        })
      );
    } else {
      dispatch(
        handleChangeMultiFields({
          patientSpecimens: multiStepFormInputs?.patientSpecimens?.map(
            (specimen) =>
              specimen.specimenId === specimenId
                ? {
                    ...specimen,
                    collectorId: value,
                  }
                : specimen
          ),
        })
      );
    }
  };

  const handleEditCollectionSite = (e, specimenId) => {
    //Changing of collection site value
    const { value } = e.target;
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs?.patientSpecimens?.map(
          (specimen) =>
            specimen.specimenId === specimenId
              ? {
                  ...specimen,
                  collectionSite: value === "Others" ? "" : value,
                }
              : specimen
        ),
      })
    );
  };

  const handleEditSelectedCollectionSiteOption = (e, specimenId, index) => {
    //Changing of selected collection site, that is used to check what option client have selected
    const { value } = e.target;
    if (index === 0) {
      dispatch(
        handleChangeMultiFields({
          patientSpecimens: multiStepFormInputs.patientSpecimens.map(
            (specimen) =>
              specimen.requiresSampleOrigin
                ? {
                    ...specimen,
                    collectionSite: value === "Others" ? "" : value,
                    selectedCollectionSiteOption: value,
                  }
                : specimen
          ),
        })
      );
    } else {
      dispatch(
        handleChangeMultiFields({
          patientSpecimens: multiStepFormInputs?.patientSpecimens?.map((data) =>
            data.specimenId === specimenId
              ? {
                  ...data,
                  selectedCollectionSiteOption: value,
                  collectionSite: value === "Others" ? "" : value,
                }
              : data
          ),
        })
      );
    }
  };

  const handleResetCollectionSiteOption = (specimenId) => {
    //Closing of input field in collection site
    dispatch(
      handleChangeMultiFields({
        patientSpecimens: multiStepFormInputs?.patientSpecimens?.map((data) =>
          data.specimenId === specimenId
            ? {
                ...data,
                selectedCollectionSiteOption: "",
                collectionSite: "",
              }
            : data
        ),
      })
    );
  };

  return (
    <Grid xs={12} sm={12} md={12} lg={12} xl={12} item>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "0.49fr 1.4fr 1.1fr 1.4fr 1.6fr 2.5fr 0.3fr",
          padding: "0.4rem",
          width: "100%",
          marginRight: "auto",
        }}
      >
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
          }}
        >
          No.
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
          }}
        >
          Specimen
        </Typography>
        <Typography
          className="required"
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
          }}
        >
          Qty/Vol
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
          }}
          className="required"
        >
          Collected By
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
          }}
          className="required"
        >
          Collection Site
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontWeight: "600",
            color: "grayishBlue.light",
            width: "max-content",
          }}
          className="required"
        >
          Collection Date Time
        </Typography>
        <div />
      </Box>
      {multiStepFormInputs?.patientSpecimens.length > 0 && (
        <Box
          sx={{
            border: "2px solid",
            borderColor: "lightBlue.light",
            alignItems: "flex-start",
            backgroundColor: "white",
            borderRadius: "5px",
            height: "auto",
            position: "relative",
            padding: "0.4rem",
            width: "98%",
            marginRight: "auto",
            overflowX: {
              xs: "scroll",
              sm: "scroll",
              md: "visible",
              lg: "visible",
              xl: "visible",
            },
          }}
        >
          {multiStepFormInputs.patientSpecimens &&
            multiStepFormInputs?.patientSpecimens?.map((data, index) => {
              const expirationHours = data.specimenExpirationInHours;
              const combinedDate = moment(data.collectionDateTime)
                .add(expirationHours, "h")
                .format();
              const currentDate = moment().format();
              const isProvidedDateTimeValid = combinedDate >= currentDate;
              const specimenHasNotes = data?.notes?.length > 0;
              const isProvidedDateInFuture = checkIfProvidedDateInFuture(
                data.collectionDateTime
              );
              const addMarginToData = data.specimen.length < 14;

              return (
                <Box
                  key={data.id}
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      " 0.3fr 1.6fr 1.1fr 1.5fr 1.6fr 2.5fr auto",
                    margin: "0.6rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "600",
                      marginTop: addMarginToData ? "0.5rem" : 0,
                    }}
                    variant="p"
                  >
                    {index + 1}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "600",
                      width: "7rem",
                      marginTop: addMarginToData ? "0.5rem" : 0,
                    }}
                    variant="p"
                  >
                    {data.specimen}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "start",
                      gap: "0.3rem",
                    }}
                  >
                    <TextField
                      error={
                        savedInputs.isSpecimenQuantityEmpty &&
                        multiStepFormInputs?.patientSpecimens?.find(
                          (specimen) => specimen.specimenId === data.specimenId
                        )?.specimenQuantity === ""
                      }
                      disabled={orderStatusThatHasUpdateCondition}
                      onChange={(e) =>
                        handleChangeSpecimenQuantity(e, data.specimenId)
                      }
                      sx={{
                        width: "50%",
                        outline: "none",
                        borderRadius: "5px",
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "2px solid",
                            borderColor: "darkBlue.secondary",
                          },
                        },
                      }}
                      value={data.specimenQuantity}
                      autoSave="off"
                      size="small"
                      variant="outlined"
                    />
                    <Typography
                      sx={{ fontWeight: "600", marginTop: "0.9rem" }}
                      variant="p"
                    >
                      {data.unitOfMeasure}
                    </Typography>
                  </Box>
                  {data?.requiresBloodDraw && (
                    <FormControl
                      disabled={orderStatusThatHasUpdateCondition}
                      error={
                        savedInputs.isSampleCollectorEmpty &&
                        multiStepFormInputs?.patientSpecimens?.find(
                          (specimen) => specimen.specimenId === data.specimenId
                        )?.collectorId === ""
                      }
                      sx={{
                        width: "8rem",
                        pointerEvents: data.requiresBloodDraw ? "auto" : "none",
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "2px solid",
                            borderColor: "darkBlue.secondary",
                          },
                        },
                        borderRadius: "5px",
                        outline: "none",
                      }}
                      size="small"
                      fullWidth
                    >
                      <Select
                        value={
                          multiStepFormInputs?.patientSpecimens?.find(
                            (specimen) =>
                              specimen.specimenId === data.specimenId
                          )?.collectorId
                        }
                        onChange={(e) =>
                          handleEditSampleCollector(e, data.specimenId, index)
                        }
                        sx={{
                          "& .MuiSvgIcon-root": {
                            color: "darkBlue.secondary",
                            fontSize: "1.5rem",
                            transform: "rotate(90deg)",
                            marginRight: "0.1rem",
                          },
                        }}
                        IconComponent={ArrowForwardIosRoundedIcon}
                      >
                        {sampleCollectorOption.map((collector) => (
                          <MenuItem
                            key={collector.id}
                            sx={{ width: "auto" }}
                            value={collector.id}
                          >
                            {collector.displayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!data?.requiresBloodDraw && (
                    <p className="font-bold ml-5 mt-3">N/A</p>
                  )}
                  {enableCollectionSite &&
                    data?.requiresSampleOrigin &&
                    data.selectedCollectionSiteOption === "Others" && (
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Input
                          error={
                            savedInputs.isCollectionSiteEmpty &&
                            multiStepFormInputs?.patientSpecimens?.find(
                              (specimen) =>
                                specimen.specimenId === data.specimenId
                            )?.collectionSite === ""
                          }
                          disabled={orderStatusThatHasUpdateCondition}
                          onChange={(e) =>
                            handleEditCollectionSite(e, data.specimenId)
                          }
                          placeholder="Please specify"
                          style={{
                            fontWeight: "600",
                            fontSize: "1.05rem",
                            width: "8rem",
                            height: "2.6rem",
                            border: "2px solid",
                            borderColor:
                              savedInputs.isCollectionSiteEmpty &&
                              multiStepFormInputs?.patientSpecimens?.find(
                                (specimen) =>
                                  specimen.specimenId === data.specimenId
                              )?.collectionSite === ""
                                ? "red"
                                : "#7393C2",
                            borderRadius: "0.25rem",
                            marginBottom: "0.4rem",
                          }}
                          value={
                            multiStepFormInputs?.patientSpecimens?.find(
                              (specimen) =>
                                specimen.specimenId === data.specimenId
                            )?.collectionSite === "Others"
                              ? ""
                              : multiStepFormInputs?.patientSpecimens?.find(
                                  (specimen) =>
                                    specimen.specimenId === data.specimenId
                                )?.collectionSite
                          }
                          type="text"
                        />
                        <Tooltip
                          title={"Unselect others"}
                          placement="right-end"
                          TransitionComponent={Zoom}
                          arrow
                        >
                          <CloseRoundedIcon
                            onClick={() =>
                              handleResetCollectionSiteOption(data.specimenId)
                            }
                            sx={{
                              cursor: "pointer",
                              fontSize: "1.2rem",
                              backgroundColor: "red",
                              borderRadius: "500px",
                              color: "white",
                              padding: "0.1rem",
                              transform: "translateX(-10px) translateY(-7px)",
                              transition: "0.3s ease-in-out",
                              ":hover": {
                                transform:
                                  "scale(1.1) translateX(-10px) translateY(-7px)",
                              },
                            }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  {enableCollectionSite &&
                    data?.requiresSampleOrigin &&
                    data.selectedCollectionSiteOption !== "Others" && (
                      <FormControl
                        disabled={orderStatusThatHasUpdateCondition}
                        error={
                          savedInputs.isCollectionSiteEmpty &&
                          multiStepFormInputs?.patientSpecimens?.find(
                            (specimen) =>
                              specimen.specimenId === data.specimenId
                          )?.collectionSite === ""
                        }
                        sx={{
                          width: "8.3rem",
                          opacity: data.requiresSampleOrigin ? "1" : "0",
                          pointerEvents: data.requiresSampleOrigin
                            ? "auto"
                            : "none",
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": {
                              border: "2px solid",
                              borderColor: "darkBlue.secondary",
                            },
                          },
                          borderRadius: "5px",
                          outline: "none",
                        }}
                        size="small"
                        fullWidth
                      >
                        <Select
                          value={
                            multiStepFormInputs?.patientSpecimens?.find(
                              (specimen) =>
                                specimen.specimenId === data.specimenId
                            )?.selectedCollectionSiteOption
                          }
                          onChange={(e) => {
                            handleEditCollectionSite(e, data.specimenId);
                            handleEditSelectedCollectionSiteOption(
                              e,
                              data.specimenId,
                              index
                            );
                          }}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "darkBlue.secondary",
                              fontSize: "1.5rem",
                              transform: "rotate(90deg)",
                              marginRight: "0.1rem",
                            },
                          }}
                          IconComponent={ArrowForwardIosRoundedIcon}
                        >
                          <MenuItem value={"Right arm"}>Right Arm</MenuItem>
                          <MenuItem value={"Left arm"}>Left Arm</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  {(!enableCollectionSite || !data?.requiresSampleOrigin) && (
                    <p className="font-bold ml-5 mt-3">N/A</p>
                  )}
                  {!enableCollectionSite && data?.requiresSampleOrigin && (
                    <p className="font-bold ml-5 mt-3">Waive</p>
                  )}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      disabled={orderStatusThatHasUpdateCondition}
                      slotProps={{
                        textField: {
                          error:
                            expirationHours !== 0 &&
                            expirationHours !== null &&
                            (!isProvidedDateTimeValid ||
                              isProvidedDateInFuture),
                          size: "small",
                        },
                      }}
                      value={moment(
                        multiStepFormInputs?.patientSpecimens?.find(
                          (specimen) => specimen.specimenId === data.specimenId
                        )?.collectionDateTime
                      )}
                      onChange={(dateTimeValue) =>
                        handleEditCollectionDateTime(
                          dateTimeValue,
                          data.specimenId
                        )
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& > fieldset": {
                            border: "2px solid",
                            borderColor: "darkBlue.secondary",
                          },
                        },
                        borderRadius: "5px",
                        outline: "none",
                      }}
                    />
                  </LocalizationProvider>
                  <Tooltip
                    title={specimenHasNotes ? "Edit note" : "Add a note"}
                    placement="right"
                    arrow
                  >
                    <EditNoteRoundedIcon
                      onClick={() =>
                        specimenHasNotes
                          ? handleEditSpecimenNote(data)
                          : handleOpenSpecimenNoteModal(data)
                      }
                      sx={{
                        transform: "translateX(7px)",
                        cursor: "pointer",
                        backgroundColor:
                          specimenHasNotes > 0 ? "green" : "darkBlue.main", //Make the color green if a specific specimen already has note
                        borderRadius: "0.2rem",
                        color: "white",
                        fontSize: "1.6rem",
                        marginTop: "0.43rem",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            })}
        </Box>
      )}
      <SpecimenNoteModal
        open={showSpecimenNoteModal}
        onCancel={handleCloseSpecimenNoteModal}
        selectedSpecimenData={selectedSpecimenData}
        setSelectedSpecimenData={setSelectedSpecimenData}
      />
    </Grid>
  );
};

export default SelectedSpecimenTable;
